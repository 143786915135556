import React, { useContext, useReducer, useEffect } from "react"
import { InterfaceContext } from "./interface-context"

export const defaultFilterContext = {
  filterOptions: {
    usage: [],
    categories: [],
    colors: [],
    sizes: [],
  },

  filterSelected: {
    usage: [],
    categories: [],
    colors: [],
    sizes: [],
    results: 0,
    refresh: true,
  },

  displayOptions: {
    sort: [],
    perPage: [],
    page: 0,
  },

  displaySelected: {
    sort: "",
    perPage: 32,
    page: 1,
  },

  products: [],
  productsSource: [],
  setFilterSelected: () => {},
  setFilterOptions: () => {},
  setProducts: () => {},
  setProductsSource: () => {},
  setDisplayOptions: () => {},
  setDisplaySelected: () => {},
  filterProducts: () => {},
  getFilterCount: () => {},
  filterUsageClickHandler: () => {},
  filterCategoryClickHandler: () => {},
  filterColorClickHandler: () => {},
  filterSizeClickHandler: () => {},
  filterApplyClickHandler: () => {},
  filterClearClickHandler: () => {},
  filterMoreClickHandler: () => {},
  filterSortClickHandler: () => {},
}

export const FilterContext = React.createContext(defaultFilterContext)
export default React.createContext(defaultFilterContext)

const reducer = (state, action) => {
  switch (action.type) {
    case "setFilterOptions":
      return {
        ...state,
        filterOptions: action.payload,
      }
    case "setFilterSelected":
      return {
        ...state,
        filterSelected: action.payload,
      }
    case "setProducts":
      return {
        ...state,
        products: action.payload,
      }
    case "setProductsSource":
      return {
        ...state,
        productsSource: action.payload,
      }
    case "setDisplayOptions":
      return {
        ...state,
        displayOptions: action.payload,
      }
    case "setDisplaySelected":
      return {
        ...state,
        displaySelected: action.payload,
      }
  }
}

export const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultFilterContext)
  const { toggleFilter } = useContext(InterfaceContext)

  // Reducers
  const setFilterOptions = (content) => {
    dispatch({ type: "setFilterOptions", payload: content })
  }

  const setFilterSelected = (content) => {
    dispatch({ type: "setFilterSelected", payload: content })
  }

  const setProducts = (content) => {
    dispatch({ type: "setProducts", payload: content })
  }

  const setProductsSource = (content) => {
    dispatch({ type: "setProductsSource", payload: content })
  }

  const setDisplayOptions = (content) => {
    dispatch({ type: "setDisplayOptions", payload: content })
  }

  const setDisplaySelected = (content) => {
    dispatch({ type: "setDisplaySelected", payload: content })
  }

  const getFilterCount = () => {
    return (
      state.filterSelected.categories.length +
      state.filterSelected.colors.length +
      state.filterSelected.sizes.length
    )
  }

  // Main filter function
  const filterProducts = (tempFilterSelected = null) => {
    tempFilterSelected = tempFilterSelected ?? state.filterSelected

    let tempProducts = [...state.productsSource]

    if (tempFilterSelected.usage.length) {
      tempProducts = tempProducts.filter(
        (p) =>
          p?.product_group?.filter(
            (pg) =>
              pg.category?.filter((c) =>
                tempFilterSelected.usage.includes(c.id)
              ).length
          ).length
      )
    }

    if (tempFilterSelected.categories.length) {
      tempProducts = tempProducts.filter(
        (p) =>
          p?.product_group?.filter(
            (pg) =>
              pg.category?.filter((c) =>
                tempFilterSelected.categories.includes(c.id)
              ).length
          ).length
      )
    }

    if (tempFilterSelected.colors.length) {
      tempProducts = tempProducts.filter((p) =>
        tempFilterSelected.colors.includes(p?.color?.name?.trim())
      )
    }

    if (tempFilterSelected.sizes.length) {
      tempProducts = tempProducts.filter(
        (p) =>
          p?.variants?.filter(
            (v) =>
              v.options?.filter((o) =>
                tempFilterSelected.sizes.includes(o.value.trim())
              ).length && v.inventory_quantity && v.inventory_quantity > 0
          ).length
      )
    }

    switch (state.displaySelected.sort) {
      case "name-asc":
        return tempProducts.sort((a, b) => {
          if (a.title < b.title) {
            return -1
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        })

      case "name-desc":
        return tempProducts.sort((a, b) => {
          if (a.title > b.title) {
            return -1
          }
          if (a.title < b.title) {
            return 1
          }
          return 0
        })

      default:
        return tempProducts
    }
  }

  // Handlers
  const filterUsageClickHandler = (id) => {
    const usage = state.filterSelected.usage?.includes(id)
      ? state.filterSelected.usage.filter((c) => c !== id)
      : [...state.filterSelected.usage, id]
    const tempFilterSelected = { ...state.filterSelected, usage }

    setFilterSelected({
      ...state.filterSelected,
      usage,
      results: filterProducts(tempFilterSelected).length,
    })
  }

  const filterCategoryClickHandler = (id) => {
    const categories = state.filterSelected.categories?.includes(id)
      ? state.filterSelected.categories.filter((c) => c !== id)
      : [...state.filterSelected.categories, id]
    const tempFilterSelected = { ...state.filterSelected, categories }

    setFilterSelected({
      ...state.filterSelected,
      categories,
      results: filterProducts(tempFilterSelected).length,
    })
  }

  const filterColorClickHandler = (name) => {
    const colors = state.filterSelected.colors?.includes(name)
      ? state.filterSelected.colors.filter((c) => c !== name)
      : [...state.filterSelected.colors, name]
    const tempFilterSelected = { ...state.filterSelected, colors }

    setFilterSelected({
      ...state.filterSelected,
      colors,
      results: filterProducts(tempFilterSelected).length,
    })
  }

  const filterSizeClickHandler = (value) => {
    const sizes = state.filterSelected.sizes?.includes(value)
      ? state.filterSelected.sizes.filter((c) => c !== value)
      : [...state.filterSelected.sizes, value]
    const tempFilterSelected = { ...state.filterSelected, sizes }

    setFilterSelected({
      ...state.filterSelected,
      sizes,
      results: filterProducts(tempFilterSelected).length,
    })
  }

  const filterApplyClickHandler = () => {
    setProducts(filterProducts(state.filterSelected))
    setDisplaySelected({ ...state.displaySelected, page: 1 })
    toggleFilter()
  }

  const filterClearClickHandler = () => {
    setDisplaySelected({ ...state.displaySelected, page: 1 })
    setFilterSelected({
      ...state.filterSelected,
      refresh: !state.filterSelected.refresh,
    })
  }

  const filterMoreClickHandler = (e) => {
    setDisplaySelected({
      ...state.displaySelected,
      page: state.displaySelected.page + 1,
    })
  }

  const filterSortClickHandler = (e) => {
    setDisplaySelected({ ...state.displaySelected, sort: e.target.value })
  }

  //Use effects
  useEffect(() => {
    let tempFilterSelected = {
      ...defaultFilterContext.filterSelected,
      results: state.productsSource.length,
    }

    setFilterSelected(tempFilterSelected)
    setProducts(filterProducts(tempFilterSelected))
  }, [state.filterSelected.refresh])

  useEffect(() => {
    setDisplaySelected({
      ...defaultFilterContext.displaySelected,
      sort: state.displaySelected.sort,
    })
    setProducts(filterProducts({ ...state.filterSelected }))
  }, [state.displaySelected.sort])

  useEffect(() => {
    setFilterSelected({ ...defaultFilterContext.filterSelected })
    setDisplaySelected({
      ...defaultFilterContext.displaySelected,
      sort: state.displaySelected.sort,
    })
    setProducts(filterProducts({ ...defaultFilterContext.filterSelected }))
  }, [state.productsSource])

  return (
    <FilterContext.Provider
      value={{
        ...state,
        setFilterOptions,
        setFilterSelected,
        setProducts,
        setProductsSource,
        setDisplayOptions,
        setDisplaySelected,
        getFilterCount,
        filterProducts,
        filterUsageClickHandler,
        filterCategoryClickHandler,
        filterColorClickHandler,
        filterSizeClickHandler,
        filterApplyClickHandler,
        filterClearClickHandler,
        filterMoreClickHandler,
        filterSortClickHandler,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilterContext = () => useContext(FilterContext)
